<template>
  <div class="content-container">
    <PageTitle :title="$t('MISSION_VIDEOS.TITLE')" class="page-title" />
    <h1 class="page-section-title section-title">
      {{ $t("MISSION_VIDEOS.NEW") }}
    </h1>
    <BackButton
      :text="$t('BACK_OVERVIEW')"
      :route-name="ROUTE_NAMES_CMS.MISSION_VIDEOS"
    />
    <FormWrapper :send-form-data="validateData" class="page-form">
      <template #form-content>

        <InputField
          :field-title="$t('MISSION_VIDEOS.TITLE')"
          :field-name="'name'"
          :rules="'required'"
          :type="'text'"
          :placeholder="$t('MISSION_VIDEOS.TITLE_PLACEHOLDER')"
          :cy-selector="'name-input'"
          :input-icon-error="require('../../../assets/icons/icn_error.svg')"
          :api-error-text="''"
          class="form-element"
        />
        <ImageInput
          :title="$t('MISSION_VIDEOS.THUMBNAIL')"
          :media-files="initialData ? initialData.mediaFiles : null"
          :start-file="initialData ? initialData.thumbnail : null"
          @file="updateThumbnail"/>

        <!-- reguliere video, ondertiteling, gebarentolk and audio descriptie. -->  
        <UploadVideo
          :field-title="$t('DIAS.VIDEO_URL') + ' - ' + $t('ASSISTANCE.REGULAR')"
          :value="initialData ? initialData.video : null"
          @update-link="updateOriginal"
          @isValid="(value) => linkIsValid(0,value)"/>

        <UploadVideo
          :field-title="$t('DIAS.VIDEO_URL') + ' - ' + $t('ASSISTANCE.SUBTITLES')"
          :value="initialData ? initialData.video : null"
          @update-link="updateSubtitles"
          @isValid="(value) => linkIsValid(1,value)"/>

        <UploadVideo
          :field-title="$t('DIAS.VIDEO_URL') + ' - ' + $t('ASSISTANCE.SIGN_LANGUAGE')"
          :value="initialData ? initialData.video : null"
          @update-link="updateDeaf"
          @isValid="(value) => linkIsValid(2,value)"/>

        <UploadVideo
          :field-title="$t('DIAS.VIDEO_URL') + ' - ' + $t('ASSISTANCE.AUDIO_DESCRIPTION')"
          :value="initialData ? initialData.video : null"
          @update-link="updateBlind"
          @isValid="(value) => linkIsValid(3,value)"/>

      </template>
      <template #button-submit>
        <button type="submit" class="pink-button submit-button">
          {{ $t("SAVE") }}
        </button>
      </template>
    </FormWrapper>
  </div>
</template>

<script>

import { GET_MISSION_VIDEO, CREATE_MISSION_VIDEO } from '@/store/modules/cms/actions'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import ImageInput from '../../../components/elements/inputFields/ImageInput.vue'
import UploadVideo from '@/components/elements/inputFields/UploadVideo.vue'
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'


export default {
  name: 'VideoCreate',
  components: {
    FormWrapper,
    ImageInput,
    UploadVideo,
    InputField,
    PageTitle,
    BackButton,
  },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isValid: [false, false, false, false],
      initialData: null,
      originalVideo: null,
      videoSubtitlesUrl: null,
      videoBlindUrl: null,
      videoDeafUrl: null,
      ROUTE_NAMES_CMS,
      thumbnail: null
    }
  },
  created() {
    this.getInitialData()
  },
  methods: {
    linkIsValid(index, value) {
      this.isValid[index] = value
    },
    getInitialData() {
      if (!this.id) {
        return
      }
      this.$store.dispatch(GET_MISSION_VIDEO).then(response => {
        this.initialData = response
      })
    },
    updateOriginal(videoUrl) {
      this.originalVideo = videoUrl
    },
    updateSubtitles(videoUrl) {
      this.videoSubtitlesUrl = videoUrl
    },
    updateBlind(videoUrl) {
      this.videoBlindUrl = videoUrl
    },
    updateDeaf(videoUrl) {
      this.videoDeafUrl = videoUrl
    },
    updateThumbnail(image) {
      this.thumbnail = image
    },
    validateData(data) {
      if (!this.isValid.every(x => x)) {
        return
      }
      data.thumbnail = this.thumbnail
      data.videoUrl = this.originalVideo
      data.videoSubtitlesUrl = this.videoSubtitlesUrl
      data.videoDeafUrl = this.videoDeafUrl
      data.videoBlindUrl = this.videoBlindUrl
      this.$store.dispatch(CREATE_MISSION_VIDEO, data).then(response => {
        if (response && (response.status === 201 || response.status === 204)) {
          this.$router.push({ name: ROUTE_NAMES_CMS.MISSION_VIDEOS })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.video-uploader{
  margin-top: rem(20);
}

</style>
